.MuiPaper-elevation2 {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 7%),
    0px 1px 5px 0px rgb(0 0 0 / 6%) !important;
}

.MuiPaper-elevation4 {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%),
    0px 1px 10px 0px rgb(0 0 0 / 6%);
}

.logo {
  width: 250px;
  height: 100%;
  max-height: 50px;
  padding-top: 10px;
  box-sizing: border-box;
  opacity: 1;
  transition: 0.2s;
}

.logo:hover {
  opacity: 0.9;
  transition: 0.2s;
}